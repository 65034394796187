import { api } from '@/lib/api/api';
import { ScheduleParamsAll } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  canApprove: boolean;
  weekDate: string;
  setWeekDate: Dispatch<SetStateAction<string>>;
  goToPending: () => void;
};

export function BannerPendingReservations({
  canApprove,
  weekDate,
  setWeekDate,
  goToPending
}: Props) {
  const {
    userContext: { currentOffice }
  } = useAuthenticated();
  const params: ScheduleParamsAll = {
    office: [currentOffice.id],
    tz: currentOffice.time_zone,
    date: weekDate,
    weeks: 10,
    limit: 1
  };
  const upcomingPending = useQuery(
    ['schedules', 'pending', params],
    async () => {
      const res = await api.client.schedules.allIndexed('pending', params);
      return res.result;
    },
    {
      keepPreviousData: true,
      enabled: !!currentOffice?.id && canApprove
    }
  );
  if (!canApprove || !upcomingPending.isSuccess || !upcomingPending.data) {
    return null;
  }
  const pendingDate = Object.keys(upcomingPending.data.dates);

  return pendingDate.length ? (
    <div>
      <div className="mt-2 w-full rounded-lg bg-gray-50 p-2 text-center text-sm sm:flex-row">
        You have some pending reservations for review in the upcoming weeks.{' '}
        <a
          href="#review_pending"
          onClick={(ev) => {
            ev.preventDefault();
            goToPending();
            setWeekDate(pendingDate[0]);
          }}
          className="mr-1 font-semibold underline"
        >
          {' '}
          Review them
        </a>
      </div>
    </div>
  ) : null;
}
