import { Disclosure } from '@headlessui/react';
import { parseISO } from 'date-fns';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import { Options } from 'react-select';
import { AuthenticatedUser, IOfficesSchedulesIndexed, ISchedule, ISlot } from '@gettactic/api';
import { groupBy } from '@/lib/utils';
import { Alert } from '@/components/basic';
import ReservationCardList from '@/components/partial/Cards/ReservationCard/ReservationCardList';
import ReservationCardListDateTitle from '@/components/partial/Cards/ReservationCard/ReservationCardListDateTitle';
import FilterByUserAndResource, {
  FormatOptionLabelProps
} from '@/components/dashboard/FilterByUserAndResource';
import NotReservations from '@/components/dashboard/reservations/NoReservations';

type Props = {
  user: AuthenticatedUser;
  officeId: string | null;
  scheduleOccupiedData: IOfficesSchedulesIndexed | null;
  embedded?: boolean;
};

type GroupByType = {
  [dateIdx: string]: ISchedule[];
};

function getSlotsInSchedules(schedules: ISchedule[]): ISlot[] {
  return removeDuplicates(schedules.flatMap((schedule) => schedule.slots));
}

function removeDuplicates<T extends { id: string }[]>(s: T): T {
  const n: any[] = [];
  s.forEach((e) => {
    if (!n.find((x) => x.id === e.id)) {
      n.push(e);
    }
  });
  return n as T;
}

function filterScheduleDataByUserAndResource(
  scheduleData: IOfficesSchedulesIndexed | null,
  query: Options<FormatOptionLabelProps>
): GroupByType | undefined {
  if (!scheduleData?.dates) return;

  if (query.length === 0) {
    return scheduleData.dates;
  }

  const users = query.filter((d) => d.type === 'user').map((d) => d.value);
  const resources = query
    .filter((d) => d.type === 'resource')
    .map((d) => d.value);
  const scheduleDataGroupByDateFiltered : GroupByType = {};

  Object.keys(scheduleData.dates).map((date) => {
    const elements = scheduleData.dates[date].filter((element) => {
      const filterByUser =
        users.length === 0 || users.includes(element.user.id);
      const filterByResource =
        resources.length === 0 || resources.includes(element.resource.id);
      return filterByUser && filterByResource;
    });

    if (elements.length > 0) {
      scheduleDataGroupByDateFiltered[date] = elements;
    }
  });

  return scheduleDataGroupByDateFiltered;
}

export function TabAdmin({
  user,
  officeId,
  scheduleOccupiedData,
  embedded = false
}: Props) {
  const admin =
    officeId &&
    scheduleOccupiedData &&
    Object.keys(scheduleOccupiedData?.dates).length > 0;
  const [scheduleOccupiedQuery, setScheduleOccupiedQuery] = useState<
    Options<FormatOptionLabelProps>
  >([]);

  const scheduleOccupiedDataGroupByDate = useMemo(
    () =>
      filterScheduleDataByUserAndResource(
        scheduleOccupiedData,
        scheduleOccupiedQuery
      ),
    [scheduleOccupiedData, scheduleOccupiedQuery]
  );

  const slugPrefix = user.organization?.slug || '';
  const scheme = process.env.NEXT_PUBLIC_TACTIC_SCHEME;
  const port = process.env.NEXT_PUBLIC_TACTIC_PORT ? `:${process.env.NEXT_PUBLIC_TACTIC_PORT}` : '';
  const domain = `${slugPrefix ? slugPrefix + '.' : ''}${process.env.NEXT_PUBLIC_TACTIC_DOMAIN}`;
  const adminUrl =  `${scheme}://${domain}${port}/admin`

  const dates = useMemo(() => {
    const dates = Object.keys(scheduleOccupiedDataGroupByDate ?? {});
    dates.sort();
    return dates;
  }, [scheduleOccupiedDataGroupByDate]);

  return (
    <>
      <Alert
        variant="tacticAlert"
        canDismiss={false}
        alertTitle=""
        alertMessage=""
        className="mt-2 px-4 py-2"
      >
        <p className="-mt-2 text-base">
          To manage reservations, please use the{' '}
          {embedded ? (
            <a href={adminUrl} target="_blank">
              <a className="cursor-pointer text-primary underline">
                new admin dashboard.
              </a>
            </a>
          ) : (
            <Link href={`/admin`}>
              <a className="cursor-pointer text-primary underline">
                new admin dashboard.
              </a>
            </Link>
          )}
        </p>
      </Alert>
      {admin ? (
        <>
          <div className="mt-5 mr-1 ml-1">
            <FilterByUserAndResource
              schedules={scheduleOccupiedData}
              onChange={setScheduleOccupiedQuery}
            />
          </div>
          {scheduleOccupiedDataGroupByDate ? (
            dates.map((date) => {
              const groupFn = (s: ISchedule) => `${s.resource.id}-${s.id}`;
              const schedulesGroupedById = groupBy(
                scheduleOccupiedDataGroupByDate[date],
                groupFn
              );
              const schedulesGroupedByIdAll = groupBy(
                scheduleOccupiedData.dates[date],
                groupFn
              );
              const schedulesMatching: ISchedule[] = [];
              const schedulesAll: ISchedule[] = [];

              Object.values(schedulesGroupedById).forEach((s) => {
                const schedule = s[0];
                const slots = getSlotsInSchedules(s);
                if (schedule) {
                  schedulesMatching.push({ ...schedule, slots });
                }
              });
              Object.values(schedulesGroupedByIdAll).forEach((s) => {
                const schedule = s[0];
                const slots = getSlotsInSchedules(s);
                if (schedule) {
                  schedulesAll.push({ ...schedule, slots });
                }
              });

              return schedulesMatching.length > 0 ? (
                <div className="ml-1 mr-1" key={date}>
                  <Disclosure defaultOpen={true}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button>
                          <ReservationCardListDateTitle
                            open={open}
                            date={parseISO(date)}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel>
                          <ReservationCardList
                            listType="admin-all"
                            schedules={schedulesAll.filter((x) =>
                              schedulesMatching.find((s) => s.id === x.id)
                            )}
                            embedded={embedded}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              ) : null;
            })
          ) : (
            <NotReservations />
          )}
        </>
      ) : (
        <NotReservations />
      )}
    </>
  );
}
